<template>
    <div id="vi-login"
         class="">
        <section class="bg-gray-50 dark:bg-gray-900 pb-10">
            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <!-- Logo -->
                <div class="flex relative items-center mb-8 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img class="max-w-[75%] sm:max-w-xs mx-auto"
                         src="/assets/img/vilor_logo.png"
                         alt="logo">
                </div>
                <!-- Form -->
                <div
                     class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">

                        <slot />
                    </div>
                </div>
                <!-- Version String -->
                <div class="text-center text-sm text-slate-500/90 mt-8">

                    <slot name="versionString" />

                </div>
            </div>
        </section>

    </div>
</template>
